<template>
    <div>
        <van-nav-bar title="查看日报" left-text="返回" @click-left="back" fixed left-arrow />

        <div class="login_form">
            <van-sticky :offset-top="43">
                <van-cell title="时间选择" clickable :value="nowTime" @click="showPicker = true" />
            </van-sticky>
            <div v-if="user.manageDeptId != 0 || reportsCompany || reportsDept">
                <van-sticky :offset-top="85">
                    <van-cell title="选择部门" clickable :value="departmentText" @click="selectDepartmentShow = true">
                        <template #extra>
                            <van-icon v-if="departmentText" name="close" class="clearSeach"
                                @click.stop="selectUserClear('dept')" />
                        </template>
                        <template #default>
                            <span v-if="user.userNameNeedTranslate == '1'">
                                <span v-if="departmentText">
                                    <TranslationOpenDataText type='departmentName' :openid='departmentText'></TranslationOpenDataText>
                                </span>
                            </span>
                            <span v-else>{{ departmentText }}</span>
                        </template>
                    </van-cell>
                </van-sticky>
                <van-sticky :offset-top="127" class="one_report_select">
                    <van-cell title="选择人员" clickable :value="userNameValue" @click="showUserPopu()"
                        value-class="userNameValue">
                        <template #extra>
                            <van-icon v-if="selectPeopleVal.name" name="close" class="clearSeach"
                                @click.stop="selectUserClear('user')" />
                        </template>
                        <template #default>
                            <span v-if="user.userNameNeedTranslate == '1'">
                                <span v-if="selectPeopleVal.name">
                                    <TranslationOpenDataText type='userName' :openid='selectPeopleVal.name'></TranslationOpenDataText>
                                </span>
                            </span>
                            <span v-else>{{ selectPeopleVal.name }}</span>
                        </template>
                    </van-cell>
                </van-sticky>
            </div>


            <van-popup v-model="showPicker" position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
                    @confirm="changeTime" @cancel="showPicker = false" />
            </van-popup>
            <!--分页，加载更多-->
            <van-pull-refresh v-model="isDownloading" @refresh="onDownRefresh">
                <van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="100" finished-text="没有更多了" @load="onLoadList">
                    <van-skeleton :v-if="report.length != 0" v-for="(item, index) in report" title avatar :row="3" :loading="false"
                        :key="index">
                        <van-panel class="one_report" :title="item.name" :status="statusTxt[item.state]">
                            <template #header>
                                <div class="van-cell van-panel__header">
                                    <div class="van-cell__title">
                                        <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                                :openid='item.name'></TranslationOpenDataText></span>
                                        <span v-else>{{ item.name }}</span>
                                    </div>
                                    <div class="van-cell__value van-panel__header-value">
                                        <span>{{ statusTxt[item.state] }}</span>
                                    </div>
                                </div>
                            </template>
                            <div class="form_text" v-if="user.timeType.onlyShowPercent == 0">
                                <span style="margin-right:20px;margin-left:5px;font-size:14px;">
                                    <span v-if="user.timeType.showCorpwxCardtime" :style="item.cardTime !=item.reportTime?'color:#ff0000;':''">考勤时长:{{item.cardTime | amounts}}h</span>
                                    总填报时长：
                                    <span>{{ parseFloat(item.reportTime).toFixed(1) }}h</span>
                                </span>
                            </div>
                            <div v-if="user.timeType.enableNewWeeklyfill == 1 && item.summary != null" style="margin-left:20px;"
                                v-html="'<b>' + item.summaryTitle + '</b><br>' + item.summary.replace(/\r\n/g, '<br>')">
                            </div>
                            <div v-for="(item1, index1) in item.data" class="one_report_data" :key="index1">
                                <div class="project_title" style="font-weight:bold;">项目：{{ item1.project }} <span
                                        :style="'color:' + statusColor[item1.state]">[
                                        <span v-if="item1.state == 0">
                                            <span v-if="user.timeType.reportAuditType == 7" >
                                                审核人：<span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                                                    :openid='item1.projectAuditorName'></TranslationOpenDataText><span v-if="item1.projectAuditState==1">√</span><span v-if="item1.deptAuditorName">、</span>
                                                                <TranslationOpenDataText type='userName'
                                                                    :openid='item1.deptAuditorName'></TranslationOpenDataText><span v-if="item1.departmentAuditState==1">√</span>
                                                        </span>
                                                        <span v-else>{{ item1.projectAuditorName }}<span v-if="item1.projectAuditState==1">√</span><span v-if="item1.deptAuditorName">、</span>{{ item1.deptAuditorName }}<span v-if="item1.departmentAuditState==1">√</span></span>
                                            </span>
                                            <span v-else>
                                                <span v-if="item1.isDeptAudit == 0">
                                                    <span v-if="item1.projectAuditState == 0">
                                                        待项目审核人<span v-if="item1.projectAuditorName != null">(
                                                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                                                    :openid='item1.projectAuditorName'></TranslationOpenDataText></span>
                                                            <span v-else>{{ item1.projectAuditorName }}</span>
                                                            )</span>审核
                                                    </span>
                                                    <span style="color:#32CD32;" v-else-if="item1.projectAuditState == 1">
                                                        项目审核人<span v-if="item1.projectAuditorName != null">(
                                                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                                                    :openid='item1.projectAuditorName'></TranslationOpenDataText></span>
                                                            <span v-else>{{ item1.projectAuditorName }}</span>
                                                            )</span>审核通过
                                                    </span>
                                                </span>
                                                <span v-else-if="item1.isDeptAudit == 1">
                                                    待
                                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='departmentName'
                                                            :openid='item1.auditDeptName'></TranslationOpenDataText></span>
                                                    <span v-else>{{ item1.auditDeptName }}</span>
                                                    审核
                                                </span>
                                            </span>
                                        </span>
                                        <span v-else>
                                            {{ statusTxt[item1.state] }}
                                        </span>
                                        <!-- {{statusTxt[item1.state]}} -->

                                        ] </span></div>
                                <div style="color:red;" v-if="item1.state == 2 && item1.rejectReason != null">原因:{{
                                    item1.rejectReason }}
                                </div>
                                <div class="project_title" v-if="item1.subProjectName != null && user.companyId != yuzhongCompId">
                                    子项目：{{ item1.subProjectName }}</div>
                                <div class="project_title" v-if="user.companyId == yuzhongCompId">
                                    角色：{{ item1.extraField1 ? roleList.find(r => r.value == item1.extraField1).label : '' }}</div>
                                <div class="project_title" v-if="user.company.packageProject == 1 && item1.groupName != null">
                                    任务分组：{{ item1.groupName }}</div>
                                <div class="project_title" v-if="user.companyId == yuzhongCompId">
                                    工作职责：{{ item1.extraField2 ? item1.extraField2Name : '' }}</div>
                                <div class="project_title" v-if="user.companyId == yuzhongCompId">
                                    工作内容：{{ item1.extraField3 ? item1.extraField3Name : '' }}</div>
                                <div class="project_title" v-if="user.company.packageProject == 1 && item1.stage != '-'">
                                    投入阶段：{{ item1.stage }}</div>
                                <!--自定义维度 -->
                                <div class="project_title" v-if="user.timeType.customDegreeActive == 1">
                                    {{ user.timeType.customDegreeName }}：{{ item1.degreeName }}</div>
                                <div class="project_title" v-if="user.timeType.customDataActive == 1">
                                    {{ user.timeType.customDataName }}：{{ item1.customData }}</div>
                                <div class="project_title" v-if="user.timeType.customTextActive == 1">
                                    {{ user.timeType.customTextName }}：{{ item1.customText }}</div>
                                <div class="project_title" v-if="user.company.packageEngineering == 1">
                                    专业进度：
                                    <span style="margin-right:10px;" v-for="progressItem in item1.professionProgress"
                                        :key="progressItem.id">{{ progressItem.professionName }}({{ progressItem.progress }}%)
                                    </span>
                                </div>

                                <div class="project_title" v-if="item1.taskId">任务：{{ item1.taskName }}</div>
                                <!--根据类型选择使用的模板 -->
                                <div v-if="item1.multiWorktime == 0">
                                    <div class="project_time" v-if="user.timeType.onlyShowPercent==0">时长：
                                        <span v-if="item1.reportTimeType == 0" style="margin-right:10px;">{{
                                            fullDayTxt[item1.timeType] }}</span>
                                        <span v-if="item1.reportTimeType == 2" style="margin-right:10px;">{{ item1.startTime + '-' +
                                            item1.endTime }}</span>{{ item1.time.toFixed(1) }}h
                                        <div class="button" v-if="item1.isOvertime == 1">加班<span v-if="item1.overtimeHours">{{
                                            item1.overtimeHours.toFixed(1) }}h</span></div>
                                    </div>
                                    <div class="project_time" v-else>用时占比：
                                        {{ item1.progress }}%
                                    </div>
                                    
                                    <div class="project_content">事项：<span v-html="item1.content"></span></div>

                                </div>
                                <div v-if="item1.multiWorktime == 1">
                                    <div>项目时长：<span style="margin-right:10px;">{{ item1.time.toFixed(1) }}h</span>
                                        <div class="button" v-if="item1.isOvertime == 1">加班<span v-if="item1.overtimeHours">{{
                                            item1.overtimeHours.toFixed(1) }}h</span></div>
                                    </div>
                                    <div style="position:relative;border:#ccc 0.5px solid;padding:3px;margin:5px 0px;"
                                        v-for="(timeItem, index) in item1.worktimeList" :key="index">
                                        <div class="project_time">时长：
                                            <!-- <span v-if="timeItem.reportTimeType == 0" style="margin-right:10px;">{{fullDayTxt[item1.timeType]}}</span> -->
                                            <span style="margin-right:10px;">{{ timeItem.startTime + '-' + timeItem.endTime
                                            }}</span>{{ timeItem.time.toFixed(1) }}h 
                                            <span style="margin-left:5px;" v-if="timeItem.detail">{{ timeItem.detail }}</span>
                                        </div>
                                        <div class="project_content">事项：<span v-html="timeItem.content"></span></div>
                                    </div>
                                </div>
                                <div style="padding:5px;text-align:center;" v-if="item1.pics != null && item1.pics.length > 0">
                                    <span v-for="(p, index) in item1.pics" :key="p" style="margin-right:10px;">
                                        <img :src="p" style="width:80px; height:80px;" @click="showLargeImg(item1.pics, index)" />
                                    </span>
                                </div>
                                <van-divider />
                            </div>
                            <van-popup v-model="imgShow" position="bottom" closeable>
                                <van-swipe class="my-swipe" indicator-color="white">
                                    <van-swipe-item v-for="(picItem, index) in tmpPics" :key="index">
                                        <img :src="picItem" style="width:100%;" />
                                    </van-swipe-item>
                                </van-swipe>
                            </van-popup>

                            <!-- 编辑 -->
                            <div slot="footer" class="foot">
                                <van-button size="small" type="primary" @click="editor(item)"
                                    v-if="item.id == user.id && (item.state == 2 || item.state == 3)">编辑日报</van-button>
                            </div>
                        </van-panel>
                    </van-skeleton>
                </van-list>
            </van-pull-refresh>

            
            <van-empty v-if="report.length == 0" description="暂无日报">
                <van-button round type="primary" class="bottom-button" @click="toWriteReport()">去填写</van-button>
            </van-empty>

            <!-- 人员选择 -->
            <van-popup v-model="selectUserShow" position="bottom" style="height: 90%">
                <div class="popupDiv">
                    <div class="popupSearch">
                        <van-search v-model.trim="searchInputValue" placeholder="输入员工姓名搜索" @clear="userListSearch"
                            @search="userListSearch" shape="round" background="#F4F4F4"></van-search>
                    </div>

                    <div class="popupCon conBorder" style="flex: 1;background-color: #fff;">
                        <div class="popupConBox">
                            <van-radio-group v-model="selectPeopleVal">
                                <van-radio v-for="uitem in showUserList" :key="uitem.id" :name="uitem" style="padding:10px"
                                    class="popupItem marginNone borderNone">
                                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName'
                                            :openid='uitem.name'></TranslationOpenDataText></span>
                                    <span v-else>{{ uitem.name ? uitem.name : '' }}</span>
                                </van-radio>
                            </van-radio-group>
                        </div>
                    </div>

                    <div class="popupBtn">
                        <van-button style="width:100%;background: #1989fa;color: #ffffff;" round
                            @click="selectUserConfirm()">确定</van-button>
                    </div>
                </div>
            </van-popup>

            <!-- 部门选择 -->
            <van-popup v-model="selectDepartmentShow" position="bottom" style="height: 90%">
                <div class="popupDiv">
                    <div class="comPaceholder">
                        已选择部门：
                        <span v-if="user.userNameNeedTranslate != '1'">
                            {{ selectDepartmentText.length > 0 ? selectDepartmentText[0].label : '请选择部门' }}
                        </span>
                        <span v-else>
                            <span v-if="selectDepartmentText.length > 0">
                                <TranslationOpenDataText type='departmentName' :openid='selectDepartmentText[0].label'></TranslationOpenDataText>
                            </span>
                            <span v-else>请选择部门</span>
                        </span>
                    </div>
                    <div class="popupCon conBorder comTree popupTree" style="flex: 1;">
                        <el-tree :data="departmentList" :props="defaultProps" @node-click="treeNodeClick">
                            <span class="custom-tree-node" slot-scope="{ node }">
                                <span v-if="user.userNameNeedTranslate == '1'">
                                    <span>
                                        <TranslationOpenDataText type='departmentName' :openid='node.label'></TranslationOpenDataText>
                                    </span>
                                </span>
                                <span v-if="user.userNameNeedTranslate != '1'">
                                    {{ node.label }}
                                </span>
                            </span>
                        </el-tree>
                    </div>
                    <div class="popupBtn">
                        <van-button style="width:100%;background: #1989fa;color: #ffffff;" round
                            @click="treeBtn">确定</van-button>
                    </div>
                </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isDownloading: false,
            isUpLoading: false, // 上拉加载
            upFinished: false, // 上拉加载完毕
            pageIndex: 0,
            hasMore: true,
            yuzhongCompId: 3385,
            roleList: [{ value: 1, label: 'CRC&LM' }, { value: 2, label: 'PM' }],
            tmpPics: [],
            imgShow: false,
            hasWaiting: false,
            state: 0,
            statusColor: ['orange', 'green', 'red'],
            user: JSON.parse(localStorage.userInfo),
            minDate: new Date(2010, 0, 1),
            maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            currentDate: new Date(),
            nowTime: this.format(new Date(new Date()), "yyyy-MM-dd"),
            showPicker: false,
            report: [],
            fullDayTxt: ['全天', '上午', '下午'],
            statusTxt: ["待审核", "已通过", "已驳回", "待提交"],
            selectUserShow: false,
            searchInputValue: '',
            userList: [],
            showUserList: [],
            firstUserList: [],
            userIdList: '',
            userNameValue: '',
            selectPeopleVal: {},

            selectDepartmentShow: false,
            selectDepartmentText: [],
            departmentText: '',
            departmentList: [],
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            reportsCompany: false,
            reportsDept: false
        };
    },
    created() {
    },
    filters:{
        // 过滤
        amounts(value) {
            if(value == NaN || value == undefined || value == 'undefined' || value == null || value == 'null') {
                return 0
            }
            var zhi = +value + 0
            return zhi.toFixed(1)
        },
    },
    methods: {
        
        onDownRefresh() {
            this.pageIndex = 1
            this.upFinished = false // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
            this.getReport();
        },
        onLoadList() {
            if(!this.hasMore) {
                this.upFinished = true
                this.isUpLoading = false
                return
            }
            this.pageIndex++;
            this.requestHttpReports();
        },
        requestHttpReports() {
            this.hasWaiting = false;
            let pames = {
                date: this.nowTime,
            }
            if (this.selectPeopleVal.id) {
                pames.userId = this.selectPeopleVal.id
            }
            if (this.departmentText && this.selectDepartmentText.length > 0) {
                pames.deptId = this.selectDepartmentText[0].id
            }
            pames.pageIndex = this.pageIndex;
            this.$axios.post("/report/getReportList", pames)
                .then(res => {
                    if (res.code == "ok") {
                        this.report = this.report.concat(res.data.data);
                        this.hasMore = res.data.hasMore;
                        this.isUpLoading = false;
                        this.isDownloading = false;
                        //计算状态
                        for (var i = 0; i < this.report.length; i++) {
                            var item = this.report[i];
                            if (item.state == 0) {
                                this.hasWaiting = true;
                            }
                        }
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败:' + res.msg);
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        showLargeImg(item, index) {
            this.imgShow = true;
            this.tmpPics = item;
        },
        // 返回
        back() {
            history.back();
        },
        getUsers(flag = true) {
            let deptId = this.selectDepartmentText[0] && this.selectDepartmentText[0].id
            this.$axios.post("/user/getEmployeeList", {
                departmentId: deptId || -1,
                pageIndex: 1,
                pageSize: -1
            })
                .then(res => {
                    if (res.code == "ok") {
                        if(flag) {
                            this.userList = res.data.records;
                            this.showUserList = res.data.records
                            this.firstUserList = res.data.records
                        } else {
                            this.userList = res.data.records;
                            this.showUserList = res.data.records
                        }
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        getDepartmentList() {
            this.$axios.post("/department/list", {})
                .then(res => {
                    if (res.code == "ok") {
                        this.departmentList = res.data;
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err => { this.$toast.clear(); });
        },
        userListSearch(val) {
            const { userNameNeedTranslate } = this.user
            if (!this.searchInputValue) {
                this.showUserList = JSON.parse(JSON.stringify(this.userList))
                return
            }

            if (userNameNeedTranslate != 1) {
                const newUserList = JSON.parse(JSON.stringify(this.userList))
                this.showUserList = newUserList.filter(item => item.name.indexOf(this.searchInputValue) != -1)
            }

            if (userNameNeedTranslate == 1) {
                let deptId = this.selectDepartmentText[0] && this.selectDepartmentText[0].id
                this.$axios.post("/user/getEmployeeList", {
                    departmentId: deptId || -1, 
                    pageIndex: 1, pageSize: -1,
                    keyword: this.searchInputValue, status: '',
                    roleId: '', cursor: '', onlyDirect: 0, matchingType: 0
                })
                    .then(res => {
                        if (res.code == "ok") {
                            const dataList = res.data.records
                            const userNameList = dataList.map(item => item.name)
                            const newUserList = JSON.parse(JSON.stringify(this.userList))
                            this.showUserList = newUserList.filter(item => userNameList.some(uItem => uItem.includes(item.name)))
                            console.log(userNameList)
                        } else {
                            this.$toast.fail('搜索失败');
                        }
                    }).catch(err => { this.$toast.clear(); });
            }
        },
        selectUserConfirm() {
            console.log(this.selectPeopleVal)

            this.selectUserShow = false
            this.getReport()
        },
        selectUserClear(str) {
            if (str == 'user') {
                this.selectPeopleVal = {}
            }
            if (str == 'dept') {
                this.departmentText = ''
                this.selectDepartmentText = []
            }
            this.getReport()
        },
        showUserPopu() {
            if (!this.departmentText) {
                this.showUserList = JSON.parse(JSON.stringify(this.firstUserList))
            } else {
                this.showUserList = JSON.parse(JSON.stringify(this.userList))
            }
            this.searchInputValue = ''
            this.selectUserShow = true
        },
        treeNodeClick(item) {
            this.selectDepartmentText = [item]
        },
        treeBtn() {
            if (this.selectDepartmentText.length <= 0) {
                this.$toast('请选择部门');
                return
            }
            this.departmentText = this.selectDepartmentText[0].label
            this.selectDepartmentShow = false
            this.getUsers(false)
            this.getReport()
        },
        // 时间转换
        format(date, pattern) {
            pattern = pattern || "yyyy-MM-dd";
            var _this = this;
            return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                switch ($0.charAt(0)) {
                    case 'y': return _this.padding(date.getFullYear(), $0.length);
                    case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                    case 'd': return _this.padding(date.getDate(), $0.length);
                    case 'w': return date.getDay() + 1;
                    case 'h': return _this.padding(date.getHours(), $0.length);
                    case 'm': return _this.padding(date.getMinutes(), $0.length);
                    case 's': return _this.padding(date.getSeconds(), $0.length);
                }
            });
        },

        padding(s, len) {
            var len = len - (s + '').length;
            for (var i = 0; i < len; i++) { s = '0' + s; }
            return s;
        },

        // 改变时间
        changeTime(time) {
            this.nowTime = this.format(new Date(time), "yyyy-MM-dd");
            this.currentDate = time;
            this.showPicker = false;
            this.getReport();
        },

        // 获取日报
        getReport() {
            this.pageIndex = 0;
            this.isUpLoading = false;
            this.report = [];
            this.requestHttpReports();
        },
        // 点击编辑
        editor(item) {
            var path = '/edit';
            if (this.user.timeType.enableNewWeeklyfill == 1) {
                //物奇要填到按周填报
                path = '/weekEdit';
            }
            this.$router.push({
                path: path,
                query: {
                    date: this.nowTime
                }
            });
        },
        // 去填写日报
        toWriteReport() {
            var path = '/edit';
            if (this.user.timeType.enableNewWeeklyfill == 1) {
                //物奇要填到按周填报
                path = '/weekEdit';
            }
            this.$router.push({
                path: path,
                query: {
                    date: this.nowTime
                }
            });
        }
    },
    mounted() {
        if (sessionStorage.targetDate != null) {
            this.nowTime = sessionStorage.targetDate;
            sessionStorage.removeItem('targetDate');
        }
        const { functionList } = this.user
        for (var i in functionList) {
            if (functionList[i].name == '查看全公司工时') {
                this.reportsCompany = true
            }
            if (functionList[i].name == '查看本部门工时') {
                this.查看本部门工时 = true
            }
        }
        this.getReport();
        this.getUsers()
        this.getDepartmentList()
    }
};
</script>

<style lang="less" scoped>
.popupTree {
    font-size: 20px;
    padding: 20px 10px;

    /deep/ .el-tree-node {
        margin-bottom: 10px;
    }

    /deep/ .el-tree-node__content>.el-tree-node__expand-icon {
        font-size: 20px;
    }
}

.login_form {
    margin-top: 46px;
}

.one_report {
    margin-bottom: 15px;
    font-size: 14px;
}

.one_report_select {
    margin-bottom: 15px;
}

.form_text {
    margin: 15px 0 15px;
    padding: 0 12px;
}

.form_btn {
    text-align: right;
}

.form_btn button {
    margin-left: 10px;
}

.one_report_data {
    margin-bottom: 10px;
    padding: 0 22px;

    div {
        line-height: 30px;
    }
}

.comPaceholder {
    margin: 10px 15px;
    background: #fff;
    padding: 10px 10px;
    border-radius: 6px;
    font-size: 20px;
    color: #606266;
}

.comTree {
    background: #ffffff;
}
</style>
<style lang="less">
.van-nav-bar .van-icon,
.van-nav-bar__text {
    color: #20a0ff;
}

.button {
    float: right;
    width: 80px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border: 1px solid red;
    color: red;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.foot {
    width: 100%;
    display: flex;
    justify-content: end;
}

.bottom-button {
    width: 160px;
    height: 40px;
    background-color: rgb(53, 130, 245);
    border-color: rgb(53, 130, 245);
}

.clearSeach {
    line-height: 0.64rem;
    position: relative;
    left: 5px;
}

.userNameValue {
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
